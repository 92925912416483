<script>
export default {
	props: ['project'],
};
</script>

<template>
	<router-link
		:to="{ path: `/projects/single-project`, query: { id: project.id } }"
		class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark transform transition-transform duration-300 hover:scale-105"
		aria-label="Single Project"
		:project="project"
	>
		<div>
			<img
				:src="project.img"
				:alt="project.title"
				class="rounded-t-xl border-none"
			/>
		</div>
		<div class="text-center px-4 py-6">
			<p
				class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
			>
				{{ project.title }}
			</p>
			<span
				class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
				>{{ project.category }}</span
			>
		</div>
	</router-link>
</template>

<style lang="scss" scoped>
img {
	width: 100%;
	height: 16rem; /* Adjust height as needed */
	object-fit: cover; /* Ensures the image fills the space while maintaining its aspect ratio */
	border-radius: 0.5rem 0.5rem 0 0; /* Matches the rounded-t-xl Tailwind class */
  }
  
</style>
