// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [

	{
		id: 1,
		title: 'CLICK CART',
		category: 'Web Application',
		img: require('@/assets/images/clickcart-svg/one.svg'),
		date: "November , 2024",
		link: "https://clickcart.arhamnatiq.com",
		tools:
			[
				'Laravel',
				'Php',
				'Jquery',
				'Bootstrap',
				'Stripe',
				'PayPal',
				'Two Checkout'
			],
		detail1: `ClickCart is a robust e-commerce application designed to streamline online shopping with three distinct roles: Admin, User, and Vendor. 
		 Dynamic Admin Panel: Fully customizable for  efficient management of users, products, and orders.
 		 Multi-Payment Gateway Integration: Supports Stripe, PayPal Checkout, Razorpay, and 2Checkout for seamless transactions.
         Real-time Chat System: Enables effective communication between users and vendors.
 		 Advanced Data Management: Powered by jQuery DataTables for a responsive and interactive user experience.
		`,


	},
	{
		id: 2,
		title: 'HOSPITAL MANAGEMENT SYSTEM',
		category: 'Web Application',
		img: require('@/assets/images/hms/one.svg'),
		date: "December , 2022",
		link: "https://www.loom.com/share/d6c43ece39db461a94ec9a3b938eec1c",
		tools:
			[
				'Laravel',
				'Php',
				'JavaScript',
				'Bootstrap',
				'Stripe'
			],
		detail1: `Hospital Management System, a user-centered platform developed to streamline healthcare operations and improve patient experience. Designed with PHP and JavaScript, this solution offers a seamless way for patients to book appointments and manage their healthcare journey through an intuitive interface.
		 Each user, whether a patient, doctor, or admin, has a tailored experience, ensuring that everyone can navigate their specific tasks with ease.`,
	},
	{
		id: 3,
		title: 'SUBMATE-PRO',
		category: 'Web Application',
		img: require('@/assets/images/sms-banner/one.svg'),
		date: "September , 2024",
		link: " https://submate-pro.arhamnatiq.com",
		tools:
			[
				'Laravel',
				'Php',
				'Vue.js',
				'Bootstrap',
				'Stripe',
				'Authorize.net',
				'PayPal',
			],
		detail1: `A Subscription Management SPA! Built using the power of Laravel 10 and Vue.js 3, this web app ensures seamless, secure user experiences with Sentinel and Tysom JWT handling robust authentication. Users can begin their journey by purchasing subscription plans, and payments are streamlined with integrations like Stripe, PayPal, and Authorize.Net, supporting easy and secure transactions. Whether it's refunds or renewals, each transaction is efficient and secure, ensuring every user's needs are met with convenience. `,
	},
	{
		id: 4,
		title: 'GUIDE MANAGING & RESERVATION BOOKING SYSTEM',
		category: 'Web Application',
		img: require('@/assets/images/gms-banners/one.svg'),
		date: "June 2023",
		link: "https://gmrs.arhamnatiq.com",
		tools:
			[
				'Laravel',
				'Php',
				'Bootstrap',
				'Stripe',
				'Authorize.net',
				'JavaScript',
				'JQuery'
			],
		detail1: ` A Guide Management and Reservation Booking System is a robust and user-centric solution designed to streamline guide reservations and enhance the booking experience. With an Admin Reservation System, admins can efficiently reserve slots for guides, ensuring smooth operations. For customers, the user-friendly frontend makes it simple to explore and book reservations, creating a seamless journey from selection to payment.`,
	},
	{
		id: 5,
		title: 'ANY-STREAM',
		category: 'Web Application',
		img: require('@/assets/images/AnyStream/one.svg'),
		date: "Aug ,2022",
		imgno1: require('@/assets/images/AnyStream/one.svg'),

		link: "",
		tools:
			[
				'Laravel',
				'Php',
				'Bootstrap',
				'JQuery',
				'JavaScript',
				'Ajax',
				'API',
			],
		detail1: `AnyStream is a comprehensive multimedia engagement platform designed to offer users an immersive experience where they can seamlessly explore and interact with multimedia content, including videos and images, related to diverse brands. With a user-centric design, AnyStream simplifies the journey from content discovery to sharing, creating a visually engaging and interactive experience.`,

	},
	{
		id: 6,
		title: 'NEXT LEVEL FOOD',
		category: 'Web Application',
		img: require('@/assets/images/burger/one.svg'),
		date: "Aug , 2023",
		client: {
			name: "Burger Latte",
			services: "Web Application Development",
			website: " https://bugerToHome.com"
		},
		tools:
			[
				'Laravel',
				'Php',
				'Vue.js',
				'Bootstrap',
				'JavaScript',
				'API',
			],
		link: "https://next-level-foods-psi.vercel.app ",
		detail1: `
        NextLevel Food is a dynamic, user-centric web application designed to connect food enthusiasts worldwide. This platform allows users to share, discover, and explore diverse recipes from various cultures, fostering a global community of culinary creativity.`

	},
	{
		id: 7,
		title: 'QUIZ APP',
		category: 'Web Application',
		img: require('@/assets/images/QuizApp/one.svg'),
		date: "May , 2024",
		objective: `The Quiz App is designed to create engaging, interactive quizzes that test users' knowledge across various topics. It allows users to participate in quiz shows, with questions designed to challenge and entertain. The app calculates scores based on the users' answers, providing instant feedback and displaying correct answers to enhance learning. The goal is to offer an intuitive and educational experience that tracks progress, encourages competition, and makes learning enjoyable and accessible for all users.`,
		tools:
			[
				'Laravel',
				'Php',
				'Vue.js',
				'Inertia.js',
				'Bootstrap',
				'JavaScript',
				'API',
				'Json'

			],
		detail1: `The Quiz App is designed to deliver an engaging and interactive quiz experience that challenges users' knowledge across a variety of topics. With carefully crafted questions, the app provides an exciting platform for users to participate in quiz shows that test their skills and knowledge. The app is focused on creating an enjoyable and educational experience where users can not only have fun but also learn from their mistakes and successes.`,
	},

	{
		id: 8,
		title: 'Y-SCHOOL SYSTEMS',
		category: 'Web Application',
		img: require('@/assets/images/ySchool/one.svg'),
		date: "October ,2024",
		client: {
			name: "Y-school Systems",
			services: "Web Application Development",
			website: " https://homeOfSchooling.com"
		},
		objective: `Y-School Systems is a comprehensive school management platform designed to centralize and streamline the management of student records, teacher information, and administrative tasks within educational institutions. This system enables efficient tracking of student performance, attendance, and personal data, while providing tools for teachers to manage classes, assignments, and communications. For administrators, Y-School Systems offers powerful tools for scheduling, resource allocation, and overall school governance, ensuring a cohesive, organized approach to school management that enhances both educational quality and operational efficiency.`,
		tools:
			[
				'Laravel',
				'Php',
				'Vue.js',
				'Bootstrap',
				'JavaScript',
				'API',
				'Jquery',
				'Stripe',
				'PayPal'
			],
		detail1: `Y-School Systems is an all-in-one school management platform built to simplify the complexities of managing student, teacher, and administrative tasks within educational institutions. With features for tracking student performance, attendance, and personal data, the platform provides a central hub for managing all aspects of school operations. Teachers are equipped with the tools to manage their classes, assignments, and communicate with students, creating a more efficient and organized teaching environment`,
		detail2: `The system also empowers administrators with powerful tools for scheduling, resource allocation, and overall governance. By centralizing key school functions, Y-School Systems enhances operational efficiency, making it easier to manage day-to-day tasks. With a user-friendly interface built using Laravel, Vue.js, and Bootstrap, the system ensures that all users—from students to administrators—have a seamless and intuitive experience that fosters better organization and workflow.`,
		detail3: `Y-School Systems is designed with both scalability and security in mind, supporting various payment methods like Stripe and PayPal for smooth transactions. The platform integrates with APIs to provide extended functionalities, ensuring it can adapt to the needs of different educational institutions. By offering a comprehensive set of tools and a responsive user interface, Y-School Systems helps schools focus on delivering high-quality education while simplifying administrative processes.`

	},
	{
		id: 9,
		title: 'LEMONI (E-commerce App)',
		category: 'Web Application',
		img: require('@/assets/images/lemoni/one.svg'),
		date: "March ,2022",


		client: {
			name: "Lemoni LLC",
			services: "Web Application Development",
			website: " https://lemoni.org"
		},
		objective: `Lemoni is an e-commerce app designed to deliver a fresh, user-friendly shopping experience, offering a wide range of products across various categories. The app focuses on simplifying the online shopping process with easy navigation, personalized recommendations, and secure payment options. With a streamlined interface and fast checkout, Lemoni aims to create a seamless, enjoyable platform for users to explore, select, and purchase products with confidence, fostering customer loyalty and satisfaction in the growing digital marketplace.`,
		tools:
			[
				'Laravel',
				'Php',
				'Bootstrap',
				'JavaScript',
				'API',
				'Stripe',
				'Authorize.net',
				'Two Checkout'
			],
		detail1: `Lemoni is a user-centric e-commerce app designed to provide a seamless and intuitive shopping experience for customers. By offering a wide variety of products across multiple categories, the app allows users to easily browse and find what they need. With its simple navigation and personalized recommendations, Lemoni ensures that every user can quickly discover products that match their preferences, making the shopping experience smooth and enjoyable.`,
		detail2: `The app integrates secure payment gateways such as Stripe, Authorize.net, and Two Checkout, providing users with flexible and trusted options for completing their transactions. With fast and efficient checkout processes, Lemoni eliminates the usual pain points of online shopping, allowing customers to complete their purchases with ease and confidence. This makes it a go-to platform for users who value both convenience and security in their shopping experience.`,
		detail3: `Built using Laravel and Bootstrap, Lemoni combines a powerful backend with a responsive and engaging frontend, ensuring a seamless shopping experience across devices. The app’s intuitive interface and fast performance create a pleasurable environment for users to explore, select, and purchase products. Lemoni is not just a shopping app but a platform designed to build lasting customer loyalty and satisfaction in an ever-expanding digital marketplace.`

	},
	{
		id: 10,
		title: 'HIRE CRUISE',
		category: 'Web Application',
		img: require('@/assets/images/ats.jpg'),
		date: "July ,2024",
		client: {
			name: "Hire Cruise",
			services: "Web Application Development",
			website: " https://hire-cruise.com"
		},
		tools:
			[
				'Laravel',
				'Php',
				'React.js',
				'Bootstrap',
				'JavaScript',
				'API',
				'Stripe',
				'PayPal',
				'Authorize.net'
			],
		detail1: `The Application Tracking System (ATS) is a comprehensive job portal designed to streamline the hiring process by connecting employers with qualified candidates. Employers can effortlessly post job openings, manage applications, and monitor candidates' progress through the various stages of the recruitment process. With an intuitive, user-friendly interface, the platform ensures employers can efficiently track and evaluate applicants, making the hiring process more organized and less time-consuming.`,
	},
	{
		id: 11,
		title: 'TRIPS & MEMBERSHIP MANAGEMENT SYSTEM',
		category: 'Web Application',
		img: require('@/assets/images/tms/one.svg'),
		date: "December ,2023",
		tools:
			[
				'Laravel',
				'Php',
				'Vue.js',
				'Bootstrap',
				'JavaScript',
				'API',
				'Stripe',
				'Authorize.net'
			],
		detail1: `The Trips and Membership Management System is an innovative platform designed to simplify travel planning for families by providing access to exclusive memberships. These memberships offer a wide range of benefits, including discounted trips, priority bookings, and access to unique travel experiences. The system is tailored to help families save time and money while enjoying personalized travel options.`,


	},
	{
		id: 12,
		title: 'DROP N SHARE ',
		category: 'Web Application',
		img: require('@/assets/images/dropnshare/one.svg'),

		date: "December ,2024",
		link: "https://dropnshare.arhamnatiq.com",
		objective: `The Trips and Membership Management System is a platform that enables parents to purchase memberships, granting them access to exclusive travel options and discounts on trips for their families. Through membership benefits, parents can seamlessly select, book, and manage trips tailored to their needs, making travel planning convenient and affordable. The system aims to provide a streamlined, user-friendly experience that simplifies the process of exploring travel options, maximizing membership value, and fostering memorable family experiences.`,
		tools:
			[
				'Laravel',
				'Php',
				'Vue.js',
				'Tailwind Css',
			],
		detail1: `Drop N Share is a file sharing tool which makes life easy while sharing large number of files . It simply inputs multiple files and generate a link . On opening that link a download button appears , on click of that button a zip folder of all the files downloaded locally into the system.`,

	},
	{
		id: 13,
		title: 'AIR COWARMING DOCKTER  ',
		category: 'WordPress',
		img: require('@/assets/images/wordpress/airco.svg'),

		date: "December ,2024",
		link: "https://aircoverwarmingdokter.be",
		tools:
			[
				'WordPress',
				'WooCommerce',
				'Elementor',
				'Wix',
			],
		detail1: `
        For the website Aircoverwarmingdokter.be, I implemented the following features to deliver a highly functional and user-friendly platform:
		 Designed a visually appealing landing page optimized for all devices, ensuring a seamless user experience.Enabled eCommerce functionality using WooCommerce, making the website ready for online sales.
		 Integrated Fluent Forms to create a multi-step form for enhanced user interaction and lead generation.Added Google Reviews to showcase customer feedback, building trust and credibility with visitors.
		`,

	},
	{
		id: 14,
		title: 'PAYLESS TRUCK DRIVING SCHOOL ',
		category: 'WordPress',
		img: require('@/assets/images/wordpress/payless.svg'),

		date: "December ,2024",
		link: "https://paylessdrivingschool.com.au ",
		tools:
			[
				'WordPress',
				'WooCommerce',
				'Elementor',
			],
		detail1: `
       Payless Driving School is an informative and user-friendly website designed to provide driving education services in Australia. The platform offers visitors comprehensive information about driving courses, booking options, and instructor profiles, ensuring an easy and accessible way for users to enroll in driving lessons.
       Developed with a focus on responsive design, the website ensures a consistent and smooth browsing experience across all devices.
		`,
	},
	{
		id: 15,
		title: 'CONFORT ZONE SHOP ',
		category: 'WordPress',
		img: require('@/assets/images/wordpress/comfort.svg'),

		date: "December ,2024",
		link: "https://comfortzoneshop.com",
		tools:
			[
				'WordPress',
				'WooCommerce',
				'Elementor',
				'Wix'
			],
		detail1: `
      Comfort Zone Shop is an online retail platform dedicated to providing customers with a diverse selection of high-quality home and lifestyle products. The website offers a user-friendly interface, making it easy to browse, select, and purchase items that bring comfort and style to everyday living.

The platform integrates modern web development techniques to deliver a seamless shopping experience, featuring responsive design, secure payment options, and efficient product categorization. 
		`,
	},
	// {
	// 	id: 12,
	// 	title: 'LevantaGpt',
	// 	category: 'Web Application',
	// 	img: require('@/assets/images/levanta-new.png'),
	// 	imgno1: require('@/assets/images/levanta-new.png'),
	// 	imgno2 : 'https://via.placeholder.com/150',
	// 	imgno3 : 'https://via.placeholder.com/150',
	// 	date: "Feb ,2024",
	// 	client : {
	// 		name : "Levanta Gpt",
	// 		services : "Web Application Development",
	// 		website  : " https://levantagpt.com"
	// 	},
	// 	objective : `Levanta GPT aims to deliver an intelligent, conversational AI platform powered by OpenAI APIs, enabling seamless, natural interactions for users seeking information or assistance. Built with Laravel, Inertia.js, and Vue, this project combines robust backend functionality with a responsive, dynamic frontend, creating an intuitive and efficient user experience. Levanta GPT is designed to streamline user queries and provide insightful responses, all within a visually engaging interface that fosters high user engagement and satisfaction.`,
	// 	tools : 
	// 	[
	// 		'Laravel',
	// 		'Php',
	// 		'Vue.js',
	// 		'Bootstrap',
	// 		'JavaScript',
	// 		'API',	
	// 		'OpenAI',
	// 		'Inertia.js',
	// 	],
	// 	detail1:`Levanta GPT is an advanced conversational AI platform powered by OpenAI APIs, built to offer intelligent, natural interactions for users in need of quick information or assistance. The platform combines robust backend capabilities with an interactive frontend, allowing users to engage in seamless conversations for an efficient, informative experience.`,
	// 	detail2:`Developed using Laravel, Inertia.js, and Vue, Levanta GPT is designed with performance and responsiveness in mind. Its dynamic frontend provides users with a visually engaging interface that enhances usability, while the backend is optimized to handle complex queries. This setup ensures users can navigate and interact with the platform intuitively, fostering a high level of engagement.`,
	// 	detail3:`Levanta GPT aims to transform how users access information, offering quick, accurate responses in a user-friendly environment. By merging the power of conversational AI with a polished, responsive design, the platform strives to meet and exceed user expectations, making it a valuable tool for those seeking immediate, insightful support.`

	// },

];

export default projects;
